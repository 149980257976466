import _extends from "@babel/runtime/helpers/esm/extends";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["hideListPoint", "layout", "limitListItems", "showAllItems", "terms", "termUrl", "title", "titleAs", "urlReplaceString"];
import React from 'react';
import { shape } from 'prop-types';
import StereoTextList from '@magalu/stereo-ui/organisms/TextList';
import { routePush, slugSearchUrl } from '@magalu/mixer-utils';
import { usePublisher } from '@magalu/mixer-publisher';
import withLayoutProps from '../../../hocs/withLayoutProps';
var TermList = function TermList(_ref) {
  var staticProps = _ref["static"];
  var _staticProps$hideList = staticProps.hideListPoint,
    hideListPoint = _staticProps$hideList === void 0 ? true : _staticProps$hideList,
    layout = staticProps.layout,
    limitListItems = staticProps.limitListItems,
    _staticProps$showAllI = staticProps.showAllItems,
    showAllItems = _staticProps$showAllI === void 0 ? true : _staticProps$showAllI,
    terms = staticProps.terms,
    _staticProps$termUrl = staticProps.termUrl,
    termUrl = _staticProps$termUrl === void 0 ? '/busca/{term}/' : _staticProps$termUrl,
    title = staticProps.title,
    titleAs = staticProps.titleAs,
    _staticProps$urlRepla = staticProps.urlReplaceString,
    urlReplaceString = _staticProps$urlRepla === void 0 ? '{term}' : _staticProps$urlRepla,
    props = _objectWithoutProperties(staticProps, _excluded);
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var list = terms.reduce(function (acc, item) {
    var term = (item == null ? void 0 : item.term) || (item == null ? void 0 : item.value) || item;
    return !term || typeof term !== 'string' ? _toConsumableArray(acc) : [].concat(_toConsumableArray(acc), [[{
      href: termUrl.replace(urlReplaceString, slugSearchUrl(term)),
      value: term
    }]]);
  }, []);
  var onLinkClick = function onLinkClick(e, _ref2) {
    var href = _ref2.href,
      value = _ref2.value;
    e.preventDefault();
    publish('listlink:click', {
      label: value
    });
    routePush({
      path: href,
      spa: true
    });
  };
  return list.length > 0 && React.createElement(StereoTextList, _extends({
    handleClickLink: onLinkClick,
    hideListPoint: hideListPoint,
    limitListItems: limitListItems,
    list: list,
    showAllItems: showAllItems,
    title: title,
    titleAs: titleAs,
    typeList: "flex"
  }, props));
};
TermList.ssr = true;
process.env.NODE_ENV !== "production" ? TermList.propTypes = {
  "static": shape({})
} : void 0;
TermList.defaultProps = {
  "static": {}
};
export default withLayoutProps(TermList);